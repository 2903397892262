blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 400;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*, :after, :before {
  box-sizing: inherit;
}

embed, iframe, img, object, video {
  height: auto;
  max-width: 100%;
}

audio {
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  text-align: left;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

/*# sourceMappingURL=index.4cf39700.css.map */
