blockquote,
body,
dd,
dl,
dt,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
html,
iframe,
legend,
li,
ol,
p,
pre,
textarea,
ul {
    margin: 0;
    padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: 400
}

ul {
    list-style: none
}

button,
input,
select,
textarea {
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    outline: none;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
}

html {
    box-sizing: border-box
}

*,
:after,
:before {
    box-sizing: inherit
}

embed,
iframe,
img,
object,
video {
    height: auto;
    max-width: 100%
}

audio {
    max-width: 100%
}

iframe {
    border: 0
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0;
    text-align: left
}

a {
    text-decoration: none;
    color: inherit;
}